



















import { Component, Vue, Prop } from "vue-property-decorator";
import Shetuzidian from "../components/shetuzidian.vue";
@Component({
  components: {
    Shetuzidian,
  },
})
export default class Name extends Vue {}
