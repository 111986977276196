


























































import { Component, Vue, Prop } from "vue-property-decorator";
import { dictionaryData } from "./shetuData";
@Component({})
export default class ShetuZidian extends Vue {
  private data: any = {};
  private currentPart: any = "定性特征参数";
  private mounted() {
    this.data = dictionaryData;
  }
}
