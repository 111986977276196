export const dictionaryData: any = {
    "定性特征参数": [
        {
            "名称": "舌色",
            "英文名称": "Tongue color",
            "字段释义": "中医所观察的舌色，主要指舌背黏膜的颜色。",
            "值域范围说明": "淡，淡红，红，暗红 , 绛，淡紫，暗紫"
        },
        {
            "名称": "舌上红点",
            "英文名称": "Red dot on Tongue",
            "字段释义": "乳头的上部顿圆，如球形，根部细小，形状如蘑菇，称为舌上红点（菌状乳头）",
            "值域范围说明": "正常，红点，新鲜瘀点，陈旧瘀点"
        },
        {
            "名称": "瘀斑",
            "英文名称": "Ecchymosis",
            "字段释义": "舌体的局部出现紫黯色或青紫色的斑点或斑块。",
            "值域范围说明": "无，有"
        },
        {
            "名称": "裂纹",
            "英文名称": "Crack",
            "字段释义": "舌质出现裂纹的舌象，裂纹有深浅之分，常见的有舌中的纵向深裂纹，舌中的短碎裂纹等。",
            "值域范围说明": "无，少而浅，少而深，多而浅，多而深"
        },
        {
            "名称": "齿痕",
            "英文名称": "Tooth marks",
            "字段释义": "舌体的边缘（舌尖和舌体两侧）有牙齿压痕的舌象。",
            "值域范围说明": "无，轻，中，重"
        },
        {
            "名称": "胖瘦",
            "英文名称": "Fat or thin",
            "字段释义": "舌体比正常的舌大而厚或是小而薄的舌象。",
            "值域范围说明": "瘦薄，适中，胖大"
        },
        {
            "名称": "老嫩",
            "英文名称": "Old or tender",
            "字段释义": "舌质的纹理粗糙，舌体干燥甚至皱缩或是舌质的纹理细腻，看起来湿润娇嫩，甚至光洁平衡的舌象。",
            "值域范围说明": "娇嫩，适中，苍老"
        },
        {
            "名称": "色泽",
            "英文名称": "Color of sublingual collaterals",
            "字段释义": "舌下络脉的颜色，一般多呈淡紫色。",
            "值域范围说明": "淡紫、青紫、紫黑"
        },
        {
            "名称": "充盈度",
            "英文名称": "Plentiful of sublingual collaterals",
            "字段释义": "舌下络脉的充盈度，一般多为不隆起或上平下隆",
            "值域范围说明": "不隆起，上平下隆，饱满隆起"
        },
        {
            "名称": "长度比",
            "英文名称": "Length ratio",
            "字段释义": "一般以不超过舌下肉阜至舌尖连线的3/5为正常",
            "值域范围说明": "<1/5，1/5，2/5，3/5，4/5"
        },
        {
            "名称": "苔色",
            "英文名称": "Tongue coating color",
            "字段释义": "舌苔的颜色。",
            "值域范围说明": "白，黄，黄白相兼，灰黑"
        },
        {
            "名称": "厚薄",
            "英文名称": "Thickness of tongue coating",
            "字段释义": "舌苔不同程度的增厚、减少的舌象。",
            "值域范围说明": "无，少，薄，稍厚，厚，极厚"
        },
        {
            "名称": "苔腻",
            "英文名称": "Mossy",
            "字段释义": "舌苔不同程度的增厚，并湿滑黏垢的舌象。",
            "值域范围说明": "无，有"
        },
        {
            "名称": "苔腐",
            "英文名称": "Moss rot",
            "字段释义": "厚苔的一种，特征是苔厚而颗粒粗大疏松，严重时状似豆腐渣堆砌于舌面。",
            "值域范围说明": "无，有"
        },
        {
            "名称": "苔剥",
            "英文名称": "Moss peeling",
            "字段释义": "舌苔发生脱落的舌象，中医有全剥苔与花剥苔之分。",
            "值域范围说明": "无，全，偏，花剥，不匀"
        },
        {
            "名称": "津液",
            "英文名称": "Saliva",
            "字段释义": "舌苔湿度。",
            "值域范围说明": "燥苔，少津，润苔，湿苔，滑苔"
        }
    ],
    "颜色特征参数": [
        {
            "名称": "Lab颜色模型",
            "英文名称": "Lab",
            "字段释义": "该模型是由CIE（国际照明委员会）于1976年制定的一种色彩模式，L为亮度；a表示从洋红到绿的范围；b表示从黄色到蓝色的范围。",
            "值域范围说明": "L取值【0,100】，当L=50时，相当于50%的黑；a和b的取值范围都是【-128,127】"
        },
        {
            "名称": "RGB颜色模型",
            "英文名称": "RGB",
            "字段释义": "该模型的R为红（Red），G为绿（Green），B为蓝（Blue），根据三基色原理，用基色光单位表示光的量，RGB颜色模型最常用的用途是显示器系统。",
            "值域范围说明": "R，G，B的取值范围都是【0,255】"
        },
        {
            "名称": "HSV颜色模型",
            "英文名称": "HSV",
            "字段释义": "该模型的H为色调（Hue），S为饱和度（Saturation)，V亮度（Value/Brightness)，HSV模型对应于画家配色的方法。",
            "值域范围说明": "H，S，V的取值范围都是【0,255】"
        },
        {
            "名称": "均值",
            "英文名称": "Mean",
            "字段释义": "所有数据之和除以数据点个数，以此表示数据集的平均大小",
            "值域范围说明": ""
        },
        {
            "名称": "方差",
            "英文名称": "Variance",
            "字段释义": "表示随机变量围绕中心值的散步程度",
            "值域范围说明": ""
        },
        {
            "名称": "斜度",
            "英文名称": "Skewness",
            "字段释义": "表示随机变量与中心分布的不对称程度，向右倾斜，值为正，向左值为负。",
            "值域范围说明": ""
        },
        {
            "名称": "峰度",
            "英文名称": "Kurtosis",
            "字段释义": "表示概率密度分布曲线在平均值处峰值高低的特征数，是对Sample构成的分布的峰值是否突兀或是平坦的描述。",
            "值域范围说明": ""
        },
        {
            "名称": "直方图",
            "英文名称": "Histogram",
            "字段释义": "又称质量分布图，是一种统计报告图，由一系列高度不等的纵向条纹或线段表示数据分布的情况。 一般用横轴表示数据类型，纵轴表示分布情况。",
            "值域范围说明": ""
        }
    ],
    "几何特征参数": [
        {
            "名称": "红点分布率",
            "英文名称": "Red dot distribution rate",
            "字段释义": "舌上红点在局部区域内分布率",
            "值域范围说明": "【0,1】"
        },
        {
            "名称": "瘀斑面积占比",
            "英文名称": "Percentage of ecchymosis area",
            "字段释义": "瘀斑面积在整个舌体面积中的占比",
            "值域范围说明": "【0,1】"
        },
        {
            "名称": "齿痕面积占比",
            "英文名称": "Proportion of tooth scar area",
            "字段释义": "齿痕面积在整个舌体面积中的占比",
            "值域范围说明": "【0,1】"
        },
        {
            "名称": "宽宽比",
            "英文名称": "Aspect ratio",
            "字段释义": "舌边距和嘴角距之间的比值",
            "值域范围说明": "【0,1】"
        }
    ],
    "纹理特征参数": [
        {
            "名称": "ASM能量",
            "英文名称": "ASM energy",
            "字段释义": "属于灰度共生特征中的参数，反映了舌图灰度分布均匀程度和纹理粗细度。",
            "值域范围说明": "数值"
        },
        {
            "名称": "对比度",
            "英文名称": "Contrast",
            "字段释义": "属于灰度共生特征的参数，反映了某个像素值及其领域像素值的亮度的对比情况。",
            "值域范围说明": "数值"
        },
        {
            "名称": "逆差矩",
            "英文名称": "Moment of deficit",
            "字段释义": "属于灰度共生特征的参数，反映图像纹理的同质性，度量图像纹理局部变化的多少。",
            "值域范围说明": ""
        },
        {
            "名称": "灰度熵",
            "英文名称": "Gray Entropy",
            "字段释义": "属于灰度共生特征的参数，表示图像中纹理的非均匀程度或复杂程度。",
            "值域范围说明": ""
        },
        {
            "名称": "短区域因子",
            "英文名称": "SZE",
            "字段释义": "灰度尺寸区域特征表征纹理一致性、非周期性或斑点状纹理效果显著，具有一定的统计学意义。",
            "值域范围说明": ""
        },
        {
            "名称": "短区高灰度级因子",
            "英文名称": "SZHGE",
            "字段释义": "灰度尺寸区域特征表征纹理一致性、非周期性或斑点状纹理效果显著，具有一定的统计学意义。",
            "值域范围说明": ""
        },
        {
            "名称": "区域百分比",
            "英文名称": "ZP",
            "字段释义": "属于灰度尺寸区域特征中的参数。",
            "值域范围说明": ""
        },
        {
            "名称": "短游程优势",
            "英文名称": "SRE",
            "字段释义": "灰度游程矩阵就是灰度值游行的长度所组成的矩阵。",
            "值域范围说明": ""
        },
        {
            "名称": "长游程优势",
            "英文名称": "LRE",
            "字段释义": "灰度游程矩阵就是灰度值游行的长度所组成的矩阵。",
            "值域范围说明": ""
        },
        {
            "名称": "低灰度区域",
            "英文名称": "LGZE",
            "字段释义": "属于灰度游程长度矩阵的参数。",
            "值域范围说明": ""
        },
        {
            "名称": "灰度不均匀",
            "英文名称": "GLN",
            "字段释义": "属于灰度游程长度矩阵的参数。",
            "值域范围说明": ""
        },
        {
            "名称": "粗糙度",
            "英文名称": "Coarseness",
            "字段释义": "粗糙度测度纹理模式的粒度大小，纹理模式粒度越大表示该纹理图像越粗糙，相反则很细。",
            "值域范围说明": ""
        },
        {
            "名称": "对比度",
            "英文名称": "Contrast",
            "字段释义": "对比度是通过对像素强度分布情况的统计。纹理的对比度一般指直方图黑白两部分极化的程度，以及灰度级动态范围。",
            "值域范围说明": ""
        },
        {
            "名称": "紧凑型",
            "英文名称": "Busyness",
            "字段释义": "区域形状特征的一种测度量，相同投影面积的空间由于平面形状不同，反映其空间分布的紧凑程度也不同。",
            "值域范围说明": ""
        },
        {
            "名称": "纹理强度",
            "英文名称": "Texture Strength",
            "字段释义": "纹理对应着灰度变化，灰度变化越快，说明纹理强度越大。",
            "值域范围说明": ""
        },
        {
            "名称": "重叠度",
            "英文名称": "Complexity",
            "字段释义": "属于邻域灰度差矩阵特征的参数。",
            "值域范围说明": ""
        }
    ]
};
