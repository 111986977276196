import { render, staticRenderFns } from "./dictionary.vue?vue&type=template&id=4fe902de&scoped=true&"
import script from "./dictionary.vue?vue&type=script&lang=ts&"
export * from "./dictionary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe902de",
  null
  
)

export default component.exports